const palette = {
  error: {
    dark: '#FF633A',
    light: '#fef1e7',
    main: '#c22f1e',
  },
  grey: {
    1: '#333333',
    2: '#737373',
    3: '#B1B1B1',
    4: '#F3F3F3',
    5: '#FBFBFB',
    6: '#5F5F5F',
    light: '#ececec',
    socialSSO: '#F2F2F2',
  },
  info: {
    light: '#f1fbfe',
    main: '#1f61a3',
  },
  link: {
    main: '#088ab8',
  },
  success: {
    light: '#f3fbef',
    main: '#61A904',
  },
  warning: {
    main: '#FBCE36',
  },
}

export default palette
