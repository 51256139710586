import Typography from '@mui/material/Typography'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import CloudIcon from '@mui/icons-material/Cloud'
import PropTypes from 'prop-types'
import { always, cond, equals } from 'ramda'

const LocationBadge = ({ as, location, color }) => {
  const chooseIcon = cond([
    [
      equals('Virtual'),
      always(
        <CloudIcon
          fontSize="small"
          sx={{ color: color ? color : 'grey.2', mr: 1 }}
        />
      ),
    ],
    [
      equals('Off-Campus'),
      always(
        <LocationCityIcon
          fontSize="small"
          sx={{ color: color ? color : 'grey.2', mr: 1 }}
        />
      ),
    ],
    [
      equals('On-Campus'),
      always(
        <AccountBalanceIcon
          fontSize="small"
          sx={{ color: color ? color : 'grey.2', mr: 1 }}
        />
      ),
    ],
  ])
  return (
    <Typography
      color={color ? color : 'grey.2'}
      component={as ? as : 'span'}
      key={location}
      sx={{ alignItems: 'center', display: 'flex' }}
      variant="body3"
    >
      {chooseIcon(location)}
      {location}
    </Typography>
  )
}

LocationBadge.propTypes = {
  as: PropTypes.string,
  color: PropTypes.string,
  location: PropTypes.string,
}

export default LocationBadge
