import { useContext } from 'react'
import CopyLinkButton from '../CopyLinkButton'
import { ResourcesContext } from '../../contexts/resourcesContext'
import PropTypes from 'prop-types'
import { not } from 'ramda'
import { Box, Typography, useMediaQuery } from '@mui/material'

const ResourceHeader = ({ pageType = null, school }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { needsCopy } = useContext(ResourcesContext)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: 'space-between',
        mt: 2,
      }}
    >
      <Box sx={{ mt: 3, width: { xs: '100%', lg: '80%' } }}>
        <Typography component="h1" variant="h4">
          Getting back on track starts with reaching out.&nbsp;
        </Typography>
        <Typography variant="body1">
          Here&apos;s what {school.name} has to help with {needsCopy}.
        </Typography>
      </Box>
      {not(isMobile) && (
        <CopyLinkButton
          resourceAnalytics={{ pageType: pageType ? pageType : 'resources' }}
        />
      )}
    </Box>
  )
}

ResourceHeader.propTypes = {
  pageType: PropTypes.string,
  school: PropTypes.object,
}

export default ResourceHeader
