import PropTypes from 'prop-types'
import { deepmerge } from '@mui/utils'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '.'

const MuiThemeProvider = ({ children, school }) => {
  // Any components that include school color overrides must be here
  const schoolTheme = {
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
        styleOverrides: {
          contained: {
            background: `linear-gradient(-180deg, ${school.gradient_start_color} 0%, ${school.gradient_stop_color} 99%)`,
            color: theme.palette.primary.main,
          },
          outlined: {
            borderColor: school.primary_color,
            color: school.primary_color,
          },
          skipLink: {
            '&:focus': {
              left: 0,
            },
            // eslint-disable-next-line sort-keys
            'background': school.primary_color,
            'color': 'white',
            'left': -222,
            'p': '6px 15px',
            'position': 'absolute',
            'top': 72,
            'transition': 'left 0.5s ease-out',
            'zIndex': '5',
          },
          text: {
            color: 'black',
          },
        },
        variants: [
          {
            props: { variant: 'single-select' },
            style: {
              '&:hover': { backgroundColor: '#dedede' },
              'border': '2px solid ' + school.secondary_color,
              'borderRadius': '50px',
              'boxShadow': '1px 2px 4px 0 rgba(79,79,79,0.25)',
              'color': 'school.secondary',
              'fontFamily': 'mark-ot-medium',
              'fontSize': 15,
              'textTransform': 'capitalize',
              'width': 'fit-content',
            },
          },
          {
            props: { variant: 'back-button' },
            style: {
              '&:hover': {
                backgroundColor: '#dedede',
                textDecoration: 'underline',
              },
              'alignItems': 'center',
              'color': 'black',
              'display': 'flex',
              'fontFamily': 'mark-ot-bold',
              'fontSize': 12,
              'letterSpacing': 1.5,
              'width': 'fit-content',
            },
          },
        ],
      },
    },
    palette: {
      school: {
        accent: school.accent_color,
        action: school.action_color,
        action2: school.action_2_color,
        action2Reverse: school.action_color_2_reverse,
        actionReverse: school.action_reverse_color,
        buttonFont: school.button_font_color,
        buttonGradient: `linear-gradient(-180deg, ${school.gradient_start_color} 0%, ${school.gradient_stop_color} 99%)`,
        flag: school.flag_color,
        gradientStart: school.gradient_start_color,
        gradientStop: school.gradient_stop_color,
        highlight: school.highlight_color,
        infoLink: school.infoLink,
        primary: school.primary_color,
        secondary: school.secondary_color,
        tertiary: school.tertiary_color,
      },
      schoolMultiColor: {
        contrastText: '#fff',
        main: school.primary_color,
      },
    },
  }

  const combined = createTheme(deepmerge(schoolTheme, theme))

  return (
    <ThemeProvider theme={combined}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

MuiThemeProvider.propTypes = {
  children: PropTypes.node,
  school: PropTypes.shape({
    accent_color: PropTypes.string,
    action_2_color: PropTypes.string,
    action_color: PropTypes.string,
    action_color_2_reverse: PropTypes.string,
    action_reverse_color: PropTypes.string,
    button_font_color: PropTypes.string,
    flag_color: PropTypes.string,
    gradient_start_color: PropTypes.string,
    gradient_stop_color: PropTypes.string,
    highlight_color: PropTypes.string,
    infoLink: PropTypes.string,
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    tertiary_color: PropTypes.string,
  }),
}

export default MuiThemeProvider
