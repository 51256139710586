/* eslint-disable sort-keys */
import sendAnalytics from '../utils/sendAnalytics'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import LanguageIcon from '@mui/icons-material/Language'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { useLocation, useNavigate } from 'react-router-dom'

const Footer = ({ school }) => {
  const { pathname } = useLocation()
  const year = new Date().getYear() + 1900
  const navigate = useNavigate()

  return (
    <>
      <Stack
        component="footer"
        direction={{
          xs: school.link_to_you ? 'column' : 'column-reverse',
          lg: 'row',
        }}
        sx={{
          backgroundColor: 'grey.5',
          borderTop: theme => `1px solid ${theme.palette.grey[4]}`,
          justifyContent: 'space-between',
          mt: 0,
          pb: 2,
          pt: 2,
        }}
      >
        <Stack spacing={0.5} sx={{ pl: 4 }}>
          <Box>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <PrivacyTipIcon sx={{ mr: 1 }} />
              <Typography
                component="span"
                sx={{ fontSize: '16px' }}
                variant="h5"
              >
                This tool is 100% anonymous.
              </Typography>
            </Box>
            <Typography sx={{ fontSize: '14px' }} variant="caption">
              No personal information is stored or shared, and you cannot be
              identified.
            </Typography>
          </Box>
          <Typography sx={{ fontSize: '12px' }} variant="caption">
            © {year} Grit Digital Health, LLC. All rights reserved. Grit does
            not claim copyright in the third party materials included on this
            site.
          </Typography>
          <Box sx={{ fontSize: '12px' }}>
            <Typography sx={{ fontSize: '12px' }} variant="caption">
              <Button
                onClick={() => {
                  navigate('/privacy-policy')
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Privacy Policy',
                    path: pathname,
                  })
                }}
                sx={{
                  fontSize: '12px',
                  textTransform: 'none',
                  fontFamily: 'mark-ot',
                }}
                variant="text"
              >
                Privacy Policy
              </Button>
              |&nbsp;
              <Button
                onClick={() => {
                  navigate('/terms-and-conditions')
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Terms of Use',
                    link_url: '/terms-and-conditions',
                    path: pathname,
                  })
                }}
                sx={{
                  fontSize: '12px',
                  textTransform: 'none',
                  fontFamily: 'mark-ot',
                }}
                variant="text"
              >
                Terms of Use
              </Button>
              |&nbsp;
              <Button
                onClick={() => {
                  navigate('/accessibility')
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Accessibility',
                    link_url: '/accessibility',
                    path: pathname,
                  })
                }}
                sx={{
                  fontSize: '12px',
                  textTransform: 'none',
                  fontFamily: 'mark-ot',
                }}
                variant="text"
              >
                Accessibility
              </Button>
            </Typography>
            &nbsp;|&nbsp;
            <Button
              href="https://forms.monday.com/forms/4823e24a3640698828ff434d04338067?r=use1"
              onClick={() =>
                sendAnalytics('footer', 'you-link', {
                  click_element: 'Website Feedback',
                  link_url: '/feedback',
                  path: pathname,
                })
              }
              rel="noreferrer"
              sx={{
                fontSize: '12px',
                textTransform: 'none',
                fontFamily: 'mark-ot',
              }}
              target="_blank"
              variant="text"
            >
              Website Feedback
            </Button>
          </Box>
        </Stack>
        {school.link_to_you ? (
          <Stack
            sx={{
              display: 'flex',
              justifyContent: { xs: 'start', lg: 'end' },
              pr: { lg: 3 },
            }}
          >
            <Box
              sx={{
                backgroundColor: 'school.primary',
                borderRadius: { xs: 0, lg: '8px' },
                display: 'flex',
                flexDirection: 'column',
                fontSize: '15px',
                height: '84px',
                mb: { xs: -2, lg: 4 },
                padding: '12px 21px',
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                width: { xs: '100%', lg: 'fit-content' },
              }}
            >
              <a
                href={'https://' + school.domain}
                onClick={() =>
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'More Resources',
                    path: pathname,
                  })
                }
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
                target="_blank"
              >
                <Box
                  sx={{
                    color: 'school.secondary',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '15px',
                    marginBottom: '7px',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                  }}
                  variant="body1"
                >
                  Find more well-being resources at
                  <Box
                    alt={school.name}
                    component="img"
                    height="25px"
                    src={school.logoImage}
                  />
                </Box>
              </a>
            </Box>
          </Stack>
        ) : (
          <Stack mb={{ xs: 4 }} ml={{ xs: 4 }} mr={{ lg: 2 }} spacing={1}>
            <Stack alignItems={'center'} direction={'row'} spacing={0.5}>
              <LanguageIcon />
              <Typography
                sx={{
                  color: 'grey.1',
                  display: 'inline-block',
                  fontFamily: 'mark-ot-bold',
                  fontSize: '15px',
                }}
              >
                ¿Desea cambiar el idioma de su navegador?
              </Typography>
            </Stack>
            <Stack alignItems={'center'} direction={'row'} spacing={0.5}>
              <DownloadForOfflineIcon sx={{ color: 'school.secondary' }} />
              <Link
                href="https://helpcompass.s3.amazonaws.com/Spanish+Browser+Instructions+(1)_es.pdf"
                onClick={() =>
                  sendAnalytics('footer', 'you-link', {
                    click_element: 'Spanish PDF',
                    path: pathname,
                  })
                }
                sx={{
                  color: 'grey.1',
                  fontFamily: 'mark-ot',
                  fontSize: '15px',
                  textDecorationColor: '#333333',
                }}
                target="blank"
                title="A link to a .pdf file is present and will open in a new browser"
              >
                Descargue la guía en PDF
              </Link>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  )
}
Footer.propTypes = {
  school: PropTypes.object,
}

export default Footer
