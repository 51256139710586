import React from 'react'
import sendAnalytics from '../../utils/sendAnalytics'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

const HelperResourceCard = ({ resourceAnalytics }) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Card
      sx={{
        borderRadius: '8px',
        boxShadow: '0px 2px 4px 0px #5F5F5F26',
        shadow: '2dp',
      }}
    >
      <CardActionArea
        onClick={() => {
          sendAnalytics(
            location.pathname,
            'helper-card',
            resourceAnalytics,
            'resource-helper'
          )
          navigate('/helper?resourcesVisited=true')
        }}
      >
        <CardHeader
          title={
            <Box
              alt="Triage Helper"
              sx={{
                backgroundImage: "url('/images/triage/helper-placeholder.svg')",
                backgroundSize: 'cover',
                height: '150px',
                width: '100%',
              }}
            />
          }
        />

        <CardContent>
          <Stack spacing={1}>
            <Typography component="h4" variant="h5">
              Not sure how to help?
            </Typography>
            <Typography variant="caption">
              Find tips and guidance for helping others in crisis.
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

HelperResourceCard.propTypes = {
  resourceAnalytics: PropTypes.object,
}

export default HelperResourceCard
