import PropTypes from 'prop-types'
import FHNLogo from '../components/FHNLogo'
import { Box, Button } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

const Header = ({ school }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Box
      component="header"
      sx={{
        alignItems: 'center',
        backgroundColor: 'school.primary',
        borderBottom: 'solid 1px white',
        borderTop: theme => `solid 4px ${theme.palette.school.secondary}`,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button aria-label="Find Help Now Home Link" href="/" variant="text">
        <Box component="span" sx={{ cursor: 'pointer', pl: 2 }}>
          {school.enable_help_compass_branding ? (
            <Box
              alt="Help Compass Logo"
              component="img"
              height={32}
              src="/images/HelpCompassIcon.svg"
            />
          ) : (
            <FHNLogo color="white" />
          )}
        </Box>
      </Button>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '75px',
          pr: 2,
        }}
      >
        <Box
          alt={school.name + ' Logo'}
          component="img"
          src={isMobile ? school.flagImage : school.triage_logo}
          width={{ xs: '32px', lg: '225px' }}
        />
      </Box>
    </Box>
  )
}

Header.propTypes = {
  school: PropTypes.object.isRequired,
}

export default Header
