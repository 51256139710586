import React from 'react'
import Header from './Header'
import PropTypes from 'prop-types'
import Footer from './Footer'
import { Box, Button } from '@mui/material'
import { isEmpty, prop } from 'ramda'
import { useNavigate } from 'react-router-dom'

const MainWrapper = props => {
  const navigate = useNavigate()

  if (isEmpty(prop('school', props))) return null

  const renderChildren = children =>
    React.Children.map(children, child =>
      React.cloneElement(child, props.school)
    )

  return (
    <>
      <Header school={prop('school', props)} />
      <Button onClick={() => navigate('#main')} variant="skipLink">
        skip to main
      </Button>
      <Box component="main" id="main">
        {renderChildren(prop('children', props))}
      </Box>
      <Footer school={prop('school', props)} />
    </>
  )
}

MainWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  school: PropTypes.object,
}

export default MainWrapper
