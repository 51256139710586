import LocationBadge from '../LocationBadge'
import ToolTip from '../FHNToolTip'
import ResourceField from './ResourceField'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import LinkIcon from '@mui/icons-material/Link'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EmailIcon from '@mui/icons-material/Email'
import DoneIcon from '@mui/icons-material/Done'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { always, and, cond, equals, map, not, prop, split, trim } from 'ramda'
import { getPrivacyIcon } from '../../utils/getPrivacyIcon'
import { Stack, Typography, Grid, Box } from '@mui/material'

const StandardResource = ({ resource, lookups, resourceAnalytics }) => {
  const [ctaList, setCtaList] = useState(null)

  // this coincides with the CTA list on the BE. Below are all the supported types
  // of CTA icons. If we add more, we will need to update the message on the
  // CMS helper text for CTA on TriageResources
  const getIcon = cond([
    [equals('map-pin'), always(<LocationOnIcon sx={{ p: 0.5 }} />)],
    [equals('link'), always(<LinkIcon sx={{ p: 0.5 }} />)],
    [equals('phone'), always(<PhoneIcon sx={{ p: 0.5 }} />)],
    [equals('calendar'), always(<CalendarMonthIcon sx={{ p: 0.5 }} />)],
    [equals('email'), always(<EmailIcon sx={{ p: 0.5 }} />)],
    [equals('clock'), always(<AccessTimeIcon sx={{ p: 0.5 }} />)],
    [equals('walk-in'), always(<DirectionsWalkIcon sx={{ p: 0.5 }} />)],
  ])

  useEffect(() => {
    if (and(not(equals('you', resource.type)), prop('cta_list', resource))) {
      setCtaList(
        map(resource => {
          const item = split(',', resource)
          return {
            label: item[0],
            link: item[1],
            // eslint-disable-next-line sort-keys
            class: item[2],
          }
        }, split('\n', prop('cta_list', resource)))
      )
    }
  }, [])

  return (
    <Stack spacing={3} sx={{ pb: 15 }}>
      <Typography component={'h1'} variant="h3">
        {resource.title}
      </Typography>
      <Stack direction="row" spacing={2}>
        {resource.location_text?.map((location, i) => (
          <LocationBadge key={i} location={lookups.location_text[location]} />
        ))}
        {resource.location_access_criterion?.name && (
          <Typography
            color="grey.2"
            component="span"
            sx={{ alignItems: 'center', display: 'flex' }}
            variant="body3"
          >
            <LocationOnIcon
              sx={{
                background: 'none',
                color: 'grey.2',
                p: 0.5,
              }}
            />
            {resource.location_access_criterion.name}
          </Typography>
        )}
        {resource.privacy_label && (
          <ToolTip
            icon={getPrivacyIcon(resource.privacy_label)}
            message="Understanding how your information may be shared is important, this label indicates to what extent this service may share your information."
            tooltipLabel={lookups.privacy_label[resource.privacy_label]}
          />
        )}
      </Stack>
      <Typography component="div" variant="body2">
        {resource.summary ? parse(resource.summary) : ''}
      </Typography>
      <Stack spacing={2}>
        <Typography component="h2" variant="h5">
          Services Available
        </Typography>
        <Grid container spacing={1}>
          {resource.services.map((service, i) => (
            <Grid item key={i} xs={6}>
              <Typography
                sx={{ alignItems: 'center', display: 'flex' }}
                variant="body2"
              >
                <DoneIcon
                  sx={{ color: 'school.secondary', fontWeight: 800, mr: 2 }}
                />
                {lookups.services[service]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Box>
        <Stack spacing={2}>
          <Typography component="h2" variant="h5">
            Cost & Insurance
          </Typography>
          <Stack spacing={2} sx={{ pl: 1 }}>
            <ToolTip
              color={'grey.2'}
              icon={
                <AttachMoneyIcon
                  fontSize="small"
                  sx={{ color: 'grey.2', mr: 1 }}
                />
              }
              message="Before accessing a service, it can be helpful to know if costs are involved. This label indicates whether this service has an expense to you."
              tooltipLabel={lookups.cost[resource.cost]}
            />
            <ToolTip
              color={'grey.2'}
              icon={
                <BeachAccessIcon
                  fontSize="small"
                  sx={{ color: 'grey.2', mr: 1 }}
                />
              }
              message="Some services may accept or require health insurance, this label indicates whether insurance is applicable to this service."
              tooltipLabel={
                'insurance ' + lookups.insurance[resource.insurance]
              }
            />
          </Stack>
        </Stack>
      </Box>
      <Typography component="h2" variant="h5">
        Get Connected
      </Typography>
      {/*Images in the public folder need to matched up to the classes we have set in the backend for all dynamic resource fields*/}
      <Grid container spacing={1}>
        {ctaList?.map((cta, ind) => (
          <Grid item key={ind} md={6} xs={12}>
            <ResourceField
              icon={getIcon(trim(prop('class', cta)))}
              link={cta.link}
              resourceAnalytics={resourceAnalytics}
              text={cta.label}
            />
          </Grid>
        ))}
        {resource.location_line_1 && resource.location_map_url && (
          <Grid item xs={6}>
            <ResourceField
              icon={<LocationOnIcon sx={{ p: 0.5 }} />}
              isMultiline={resource.location_line_2?.length > 0}
              link={resource.location_map_url}
              resourceAnalytics={resourceAnalytics}
              text={resource.location_line_1}
              text2={resource.location_line_2}
            />
          </Grid>
        )}
        {resource.hours_text &&
          equals('view', resource.hours_text) &&
          resource.hours_link_url && (
            <Grid item xs={6}>
              <ResourceField
                icon={<AccessTimeIcon sx={{ p: 0.5 }} />}
                link={resource.hours_link_url}
                resourceAnalytics={resourceAnalytics}
                text={lookups.hours_text[resource.hours_text]}
              />
            </Grid>
          )}
        {resource.hours_text && not(equals('view', resource.hours_text)) && (
          <Grid item xs={6}>
            <Typography
              color="grey.2"
              component="span"
              sx={{ alignItems: 'center', display: 'flex' }}
              textTransform={'capitalize'}
              variant="body3"
            >
              <Brightness4Icon
                sx={{
                  background: 'none',
                  color: 'grey.2',
                  p: 0.5,
                }}
              />
              {resource.hours_text}
            </Typography>
          </Grid>
        )}
        {resource.link && (
          <Grid item xs={6}>
            <ResourceField
              icon={<RssFeedIcon sx={{ p: 0.5 }} />}
              link={resource.link}
              resourceAnalytics={resourceAnalytics}
              text="Visit website"
            />
          </Grid>
        )}
        {resource.phone_number && (
          <Grid item xs={6}>
            <ResourceField
              icon={<PhoneIcon sx={{ p: 0.5 }} />}
              isPhoneNumber={true}
              link={resource.phone_number}
              resourceAnalytics={resourceAnalytics}
              text={resource.phone_number}
            />
          </Grid>
        )}
        {resource.email_address && (
          <Grid item xs={6}>
            <ResourceField
              icon={<EmailIcon sx={{ p: 0.5 }} />}
              isEmail={true}
              link={resource.email_address}
              resourceAnalytics={resourceAnalytics}
              text={resource.email_address}
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  )
}

StandardResource.propTypes = {
  lookups: PropTypes.object,
  resource: PropTypes.object,
  resourceAnalytics: PropTypes.object,
}

export default StandardResource
