import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Icon,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { always, cond, equals, filter, includes, length } from 'ramda'

const Needs = ({ categories, onChange, selectedNeeds }) => {
  const getIcons = cond([
    [equals('Physical Safety'), always('favorite')],
    [equals('Basic Needs'), always('attach_money')],
    [equals('Mental Health'), always('spa')],
    [equals('Physical Health'), always('monitor_heart')],
    [equals('Incident Reporting'), always('auto_stories')],
    [equals('Conflict Resolution'), always('forum')],
    [equals('Guidance or Advocacy'), always('group')],
  ])
  return (
    <Box>
      {categories.map((category, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              key={'accordion-item-' + index}
            >
              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <Icon sx={{ mr: 1 }}>
                  {category.icon_key
                    ? category.icon_key
                    : getIcons(category.name)}
                </Icon>
                {category.name}
                {
                  <Typography sx={{ pl: 0.5 }} variant="body2">
                    (
                    {length(
                      filter(
                        need => includes(need.id, selectedNeeds),
                        category.needs
                      )
                    )}
                    )
                  </Typography>
                }
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {category.needs.map((need, needsIndex) => (
                <Chip
                  color="schoolMultiColor"
                  id={needsIndex}
                  key={needsIndex}
                  label={need.name}
                  onClick={() => onChange({ category: category.name, ...need })}
                  sx={
                    includes(need.id, selectedNeeds)
                      ? {
                          fontFamily: 'mark-ot',
                          fontSize: 15,
                          fontWeight: 700,
                          mb: 1,
                          mr: 1,
                          '&:hover': {
                            color: 'school.primary',
                          },
                        }
                      : { fontFamily: 'mark-ot', fontSize: 15, mb: 1, mr: 1 }
                  }
                  variant={
                    includes(need.id, selectedNeeds) ? 'filled' : 'outlined'
                  }
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}

Needs.propTypes = {
  categories: PropTypes.array,
  onChange: PropTypes.func,
  selectedNeeds: PropTypes.array,
}

Needs.defaultProps = {
  categories: [],
}

export default Needs
