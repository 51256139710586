import { VelocityTransitionGroup } from 'velocity-react'
import CopyLinkButton from '../CopyLinkButton'
import sendAnalytics from '../../utils/sendAnalytics'
import { QuestionContext } from '../../contexts/questionContext'
import { useInitDataContext } from '../../contexts/initDataContext'
import Button from '@mui/material/Button'
import { Box, Container, Stack } from '@mui/material'
import StandardResource from './StandardResource'
import YouResource from './YouResource'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'
import ResourceSkeletonLoader from './ResourceSkeletonLoader'
import { useContext } from 'react'
import { ResourcesContext } from '../../contexts/resourcesContext'
import { isNil, not, or } from 'ramda'
import { useLocation, useNavigate } from 'react-router-dom'

const enterAnimation = {
  animation: { opacity: 1 },
  duration: 500,
  easing: 'easeOutCubic',
  style: { opacity: 0 },
}

const Resource = ({ resource }) => {
  const { baseResourceAnalytics } = useContext(ResourcesContext)
  const { previousAnswers, previousNeeds, seeAllTopics } =
    useContext(QuestionContext)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { lookups } = useInitDataContext()
  const location = useLocation()
  const navigate = useNavigate()
  const previousPath = localStorage.getItem('previousPath')
  const needTitles = previousNeeds.map(need => need.name)
  const accessCriteria = []
  const keys = Object.keys(previousAnswers)
  keys.forEach(key => {
    const previousAnswer = previousAnswers[key]
    previousAnswer.answers.forEach(answer => {
      accessCriteria.push(answer.name)
    })
  })
  const isEmergency = previousPath ? previousPath.includes('/emergency/') : ''
  const isHelper = previousPath ? previousPath.includes('them') : ''
  const resourceAnalytics = {
    accessCriteria,
    allTopics: seeAllTopics,
    isEmergency,
    isHelper,
    needTitles,
    needs: previousNeeds,
    path: location.pathname,
    resource,
    type: resource ? resource.type : null,
    ...baseResourceAnalytics,
  }

  if (or(isNil(resource), isNil(lookups))) return <ResourceSkeletonLoader />

  // if the history is greater than 2, that means the user found results via
  // the traditional question flow and didn't have them linked to them.
  // thus we display the back to start button
  // if the history length is less than 2, we assume they
  // went had the results linked to them and display a start new search button
  // since it is theoretically their first time here
  // I know this breaks down if they put a link in an already used tab...
  // but who does that? Crazy people.
  const resourcesVisited =
    typeof window !== 'undefined' ? window.history.length > 2 : false

  return (
    <VelocityTransitionGroup enter={enterAnimation} runOnMount={true}>
      <Container>
        <Stack spacing={2} sx={{ maxWidth: '700px', mx: 'auto', width: '90%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {resourcesVisited ? (
              <Button
                onClick={() => {
                  sendAnalytics(
                    window.location,
                    'back-nav',
                    { ...resourceAnalytics },
                    'resource'
                  )
                  navigate(-1)
                }}
                variant="back-button"
              >
                <ArrowBackIosIcon sx={{ fontSize: 'small', ml: -2 }} /> Back To
                Results
              </Button>
            ) : (
              <Button onClick={() => navigate('/triage')} variant="back-button">
                Start New Search
              </Button>
            )}
            {not(isMobile) && (
              <CopyLinkButton
                resourceAnalytics={{
                  pageType: 'resource',
                  ...resourceAnalytics,
                }}
              />
            )}
          </Box>

          {resource.type === 'you' ? (
            <YouResource resource={resource} />
          ) : (
            <StandardResource
              lookups={lookups}
              resource={resource}
              resourceAnalytics={resourceAnalytics}
            />
          )}
        </Stack>
      </Container>
    </VelocityTransitionGroup>
  )
}

Resource.propTypes = {
  resource: PropTypes.object,
}

export default Resource
