import { Box, ListItem, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const QuestionNavItem = ({ active, step, text }) => (
  <ListItem sx={{ borderLeft: `3px solid ${active ? 'black' : 'none'}` }}>
    <Box>
      <Typography
        color={active ? 'grey.1' : 'grey.6'}
        sx={{
          display: 'block',
          fontSize: 12,
          lineHeight: 1.5,
          textTransform: 'uppercase',
        }}
        variant="markOtBold"
      >
        Step&nbsp;{step}
      </Typography>
      <Typography
        color={active ? 'grey.1' : 'grey.6'}
        sx={{ fontSize: 15, textTransform: 'capitalize' }}
        variant="markOtBold"
      >
        {text}
      </Typography>
    </Box>
  </ListItem>
)

QuestionNavItem.propTypes = {
  active: PropTypes.bool,
  step: PropTypes.number,
  text: PropTypes.string,
}

export default QuestionNavItem
