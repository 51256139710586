import { VelocityTransitionGroup } from 'velocity-react'
import CopyLinkButton from './CopyLinkButton'
import sendAnalytics from '../utils/sendAnalytics'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import PropTypes from 'prop-types'
import { not } from 'ramda'
import {
  Box,
  List,
  ListItem,
  Stack,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

const enterAnimation = {
  animation: { opacity: 1 },
  duration: 500,
  easing: 'easeOutCubic',
  style: { opacity: 0 },
}

const helperImg = '/images/triage/helper-placeholder.svg'

function HelperDetail({ resourcesVisited }) {
  const navigate = useNavigate()
  const jumpToScenarioDiv = id => {
    document.getElementById(id).scrollIntoView()
  }
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <VelocityTransitionGroup enter={enterAnimation} runOnMount={true}>
      <Box
        id="helper-page"
        sx={{ m: 'auto', maxWidth: 684, pb: 8, position: 'relative' }}
      >
        <Box
          component="nav"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: { xs: 'space-around', lg: 'space-between' },
            mt: 3,
            width: '100%',
          }}
        >
          {resourcesVisited ? (
            <Button
              onClick={() => {
                sendAnalytics(
                  window.location.href,
                  'back-nav',
                  {},
                  'resource-helper'
                )
                navigate(-1)
              }}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              <KeyboardArrowLeftIcon fontSize="small" sx={{ mr: 0.5 }} />
              Back To Results
            </Button>
          ) : (
            <Button
              onClick={() => navigate('/triage')}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              <KeyboardArrowLeftIcon fontSize="small" sx={{ mr: 0.5 }} /> Start
              New Search
            </Button>
          )}
          <Box>
            {not(isMobile) && (
              <CopyLinkButton
                resourceAnalytics={{ pageType: 'resource-helper' }}
              />
            )}
          </Box>
        </Box>

        <Stack spacing={3} sx={{ m: 4 }}>
          <Box component="section">
            <Box
              alt="Helper Image"
              component="img"
              src={helperImg}
              sx={{ width: '100%' }}
            />
            <Typography variant="h1">Not sure how to help?</Typography>
            <Typography
              paragraph
              sx={{ color: '#b1b1b1', fontWeight: 'bold', mt: 5 }}
            >
              One of the most caring things you can do for a friend is listen.
              You don’t have to “fix” the problem or have all the answers.
              Asking directly about how someone is feeling can be a good door
              opener. Most importantly, if you don&apos;t feel you can offer
              support, find someone who can.
            </Typography>
          </Box>
          <Box component="hr" sx={{ color: 'grey.4' }} />
          <Box sx={{ mb: 2 }}>Jump to Scenario</Box>
          <List disablePadding>
            <ListItem disablePadding>
              <Button
                href="#offer-support"
                sx={{ color: 'link.main', textAlign: 'left' }}
                variant="text"
              >
                When to Offer Support{' '}
                <ArrowForwardIcon fontSize="small" sx={{ ml: 0.5 }} />
              </Button>
            </ListItem>
            <ListItem disablePadding>
              <Button
                href="#difficult-convos"
                sx={{
                  color: 'link.main',
                  textAlign: 'left',
                  textDecoration: 'none',
                }}
                variant="text"
              >
                Having Difficult Conversations{' '}
                <ArrowForwardIcon fontSize="small" sx={{ ml: 0.5 }} />
              </Button>
            </ListItem>
            <ListItem disablePadding>
              <Button
                href="#immediate-crisis"
                sx={{ color: 'link.main', textAlign: 'left' }}
                variant="text"
              >
                Helping Someone in Immediate Crisis{' '}
                <ArrowForwardIcon fontSize="small" sx={{ ml: 0.5 }} />
              </Button>
            </ListItem>
          </List>
          <Box component="hr" sx={{ color: 'grey.4' }} />
          <Box component="section" id="offer-support">
            <Typography sx={{ mb: 3 }} variant="h3">
              {' '}
              When to Offer Support
            </Typography>
            <Typography paragraph>
              Talking to acquaintances, friends, and/or family about personal
              challenges can be an opportunity to provide information, support,
              and guidance.
            </Typography>
            <Typography paragraph>
              Feelings of distress can take many forms - whether it is coping
              with a stressful event, a family challenge, financial hardship, or
              a mental/physical health condition. We can all play a role in
              helping someone navigate a path forward. The process starts with
              recognizing warning signs that may communicate an individual is
              struggling or experiencing emotional pain.
            </Typography>
            <Typography variant="h4">Signs to Look For</Typography>
            <Box>
              <Typography component={'span'}>
                When a person is in distress, take note of “warning signs” or
                “red flags” that indicate something might be “off.” Signs may be
                expressed through{' '}
              </Typography>
              <Typography component={'span'} fontFamily="mark-ot-bold">
                words, actions, and/or feelings.{' '}
              </Typography>
              <Typography component={'span'}>For example:</Typography>
            </Box>
            <ul>
              <li>Not answering texts, phone calls, or emails</li>
              <li>Changes in appearance</li>
              <li>
                Consistent comments about being overwhelmed, lost, not knowing
                what to do, or feeling stuck
              </li>
              <li>Changes in substance use</li>
              <li>Increased frustration and/or anger</li>
              <li>Increased confusion and/or lethargy</li>
              <li>Withdrawn</li>
              <li>Feeling like a burden</li>
              <li>Hopelessness</li>
              <li>Expressing a plan to take their life</li>
              <li>
                Making jokes or innuendos about taking their life (“they won’t
                miss me”)
              </li>
              <li>
                In possession of firearms or other lethal means (eg.,
                medications, sharp objects, etc.)
              </li>
            </ul>
            <Typography sx={{ mb: 3 }} variant="h4">
              Key Takeaway
            </Typography>
            <Typography paragraph>
              Trust your gut - if someone you know is acting differently in
              several areas of their life, it&apos;s likely a sign something is
              off. The best thing you can do is try to connect to learn more, or
              find someone who can.
            </Typography>
            <Button
              onClick={() => jumpToScenarioDiv('helper-page')}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              Back to Top <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Button>
          </Box>
          <Box component="hr" sx={{ color: 'grey.4' }} />

          <Box component="section" id="difficult-convos">
            <Typography sx={{ mb: 3 }} variant="h3">
              Having Difficult Conversations
            </Typography>
            <Typography paragraph>
              Offering help can feel intimidating. The good news - showing
              support and connecting others to care can be simple. The most
              important thing to remember is to be curious and direct about your
              concerns and desire to help.
            </Typography>
            <Typography variant="h4">Tips</Typography>
            <ul>
              <li>Have the conversation in a private setting</li>
              <li>Be curious and empathetic</li>
              <li>
                Be direct in sharing your concern (consider sharing observations
                about the warning signs that caused you concern)
              </li>
              <li>Be clear that you want to help</li>
              <li>
                Do more listening than talking; resist the temptation to
                interrupt
              </li>
              <li>Don&apos;t express judgement</li>
              <li>Don&apos;t rush the conversation</li>
              <li>Plan a next step or check-in</li>
            </ul>
            <Typography variant="h4">Thought Starters</Typography>
            <ul>
              <li>
                &quot;I&apos;ve noticed you haven&apos;t seemed like yourself
                recently, is everything okay?&quot;
              </li>
              <li>
                &quot;I know that ______ (i.e. you&apos;ve been missing class,
                finances have been tight, a lot has been going on with your
                family) lately, is there anything I can do to help?&quot;
              </li>
              <li>
                &quot;I&apos;m sorry you are going through this and really glad
                you told me. Have you considered seeking additional help? We can
                look together right now.&quot;
              </li>
            </ul>
            <Typography sx={{ mb: 3 }} variant="h4">
              Key Takeaway
            </Typography>
            <Typography paragraph>
              If you are worried about someone, the most important thing to do
              is say something. Simply taking the time to listen can go a long
              way and open the door to someone connecting with additional
              support. If you are still worried after an initial conversation,
              be persistent and follow up to show your support.
            </Typography>
            <Button
              onClick={() => jumpToScenarioDiv('helper-page')}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              Back to Top <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Button>
          </Box>

          <Box component="hr" sx={{ color: 'grey.4' }} />

          <Box component="section" id="immediate-crisis">
            <Typography sx={{ mb: 3 }} variant="h3">
              Helping Someone in Immediate Crisis
            </Typography>
            <Typography paragraph>
              If you are concerned about an individual and/or their safety,
              trust your instincts. If they are talking about suicide or self
              harm, connect with an appropriate professional, contact the
              Suicide & Crisis Lifeline by calling or texting 988, or call 911
              if it is an immediate medical or life threatening emergency.
            </Typography>
            <Typography fontFamily={'mark-ot-bold'} paragraph>
              Whatever you do, do not keep it a secret and be clear in wanting
              to help. Here’s a few examples to clearly convey this message:
            </Typography>
            <ul>
              <li>
                This is bigger than you and me. I think we need more help than I
                can provide. I know who to reach out to.
              </li>
              <li>
                I&apos;m not going to leave you alone. I want to be sure you
                stay safe. Let&apos;s call 988 together.
              </li>
              <li>
                Who would you like to reach out to? (Therapist, coach, parent,
                mentor, etc.)
              </li>
            </ul>
            <Typography paragraph>
              While reaching out for additional support might feel overwhelming,
              it&apos;s important to remember that individuals who receive
              emergency care are almost always grateful for the support after
              the crisis subsides.
            </Typography>
            <Typography variant="h4">How to take action</Typography>
            <ul>
              <li>
                If someone is in an immediate crisis, it&apos;s important that
                you or someone else stay with the person (assuming there is no
                immediate danger to bystanders) while you get support
              </li>
              <li>
                If you are able, offer one or two choices for connecting the
                individual to additional support, while being insistent that
                they connect. For example: &quot;I am going to get you some
                help. Would you like me to walk with you to the health center,
                call a crisis line, or call 988?&quot;
              </li>
            </ul>
            <Typography sx={{ mb: 3 }} variant="h4">
              Key Takeaway
            </Typography>
            <Typography paragraph>
              Being non-judgemental, empathetic, and intentional about your
              desire to connect a person with care is the most effective way to
              provide support. In many instances, individuals will not get help
              on their own. By taking action, you can shorten the crisis and the
              individual can get the support they need. And remember, if you
              don&apos;t feel you can provide the support that the individual
              needs, find someone who can.
            </Typography>
            <Button
              onClick={() => jumpToScenarioDiv('helper-page')}
              sx={{ color: 'link.main' }}
              variant="text"
            >
              Back to Top <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
            </Button>
          </Box>
        </Stack>
      </Box>
    </VelocityTransitionGroup>
  )
}
HelperDetail.propTypes = {
  resourcesVisited: PropTypes.bool,
}

export default HelperDetail
