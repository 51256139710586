import React from 'react'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import sendAnalytics from '../utils/sendAnalytics'
import Icon from '@mui/material/Icon'
import { Modal, Stack } from '@mui/material'

const CopyLinkButton = ({ resourceAnalytics }) => {
  const [clicked, setClicked] = useState(false)

  const handleCopyLink = () => {
    sendAnalytics(
      window.location,
      'copy-link',
      { ...resourceAnalytics },
      resourceAnalytics.pageType
    )
    navigator.clipboard.writeText(window.location.href)
    setClicked(true)
  }

  const style = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    left: '50%',
    p: 4,
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 520,
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        p: 0,
      }}
    >
      <Modal open={clicked}>
        <Stack spacing={3} sx={style}>
          <Typography variant="h2">Copied!</Typography>
          <Typography variant="body1">
            This unique link contains all of your results so that you can
            revisit them any time, and/or share these resources with others.
            Save or send this link to access this resource at a later date.
          </Typography>
          <Typography variant="body1">
            <strong>Helping someone else?</strong> Explore{' '}
            <a href={'/helper'}>helpful tips</a> for offering support!
          </Typography>
          <Button onClick={() => setClicked(false)} sx={{ color: 'black' }}>
            Close
          </Button>
        </Stack>
      </Modal>
      <Button
        onClick={() => handleCopyLink()}
        sx={{ alignItems: 'center', display: 'flex', p: 2 }}
        variant="single-select"
      >
        <Icon sx={{ mr: 1 }}>link</Icon>
        <Typography variant="body2">Copy Link</Typography>
      </Button>
    </Box>
  )
}

export default CopyLinkButton
