import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import { Tooltip, Box, Typography } from '@mui/material'

const SelectAllInput = ({
  handleSeeAllTopicsChange,
  label,
  selectAll,
  tooltip,
}) => {
  return (
    <Tooltip
      aria-label={tooltip}
      sx={{ lineHeight: 0.5 }}
      title={<Typography variant="caption">{tooltip}</Typography>}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 1,
          mt: 3.5,
        }}
      >
        <input
          checked={selectAll}
          id="select-all"
          name="select-all"
          onChange={handleSeeAllTopicsChange}
          type="checkbox"
          value={selectAll}
        />
        <label htmlFor="select-all">
          <Typography sx={{ ml: 1 }} variant="body2">
            {label}
          </Typography>
        </label>
        <HelpIcon fontSize="small" sx={{ color: 'grey.2', ml: 2 }} />
      </Box>
    </Tooltip>
  )
}
SelectAllInput.propTypes = {
  handleSeeAllTopicsChange: PropTypes.func,
  label: PropTypes.string,
  selectAll: PropTypes.bool,
  tooltip: PropTypes.string,
}

export default SelectAllInput
