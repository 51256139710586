import PropTypes from 'prop-types'
import QuestionNavItem from './QuestionNavItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ChatIcon from '@mui/icons-material/Chat'
import { equals, inc, map, range } from 'ramda'
import {
  Grid,
  List,
  Stack,
  Typography,
  Box,
  Button,
  ListItem,
} from '@mui/material'

const QuestionNavSidebar = ({
  handleBackButton,
  progressStep,
  progressStepText,
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  // we currently have two steps in our flow:
  // 1. who needs help?
  // 2. what kind of help?
  // if we add more, we will need to update this component.
  // Note: our progressStepText has a third step in it at the moment, however we have disabled
  // that step for now (i dont know why at time of writing) and thus we short circuit with totalSteps = 2 instead
  // of the more obvious totalSteps = R.length(progressStepText)
  const totalSteps = 2

  return isMobile ? (
    <Grid container sx={{ borderBottom: '1px solid', display: 'flex', mb: 1 }}>
      <Grid item xs={1}>
        <Button
          onClick={handleBackButton}
          sx={{ height: '100%' }}
          variant="text"
        >
          <ArrowBackIosIcon />
        </Button>
      </Grid>
      <Grid
        item
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          pb: 2,
        }}
        xs={10}
      >
        <List sx={{ display: 'flex', width: '85%' }}>
          {map(
            step => (
              <ListItem
                key={step}
                sx={{
                  borderBottom: theme =>
                    `6px solid ${
                      equals(progressStep, step)
                        ? theme.palette.school.secondary
                        : theme.palette.grey.light
                    }`,
                  mx: 0.5,
                }}
              />
            ),
            range(0, totalSteps)
          )}
        </List>
        <Typography
          component="span"
          sx={{ color: 'grey.1', fontSize: 12, textTransform: 'uppercase' }}
          variant="markOtBold"
        >
          Step {inc(progressStep)}
        </Typography>{' '}
        <Typography
          sx={{
            color: 'grey.1',
            fontSize: 15,
            my: 1,
            textTransform: 'capitalize',
          }}
          variant="markOtBold"
        >
          &nbsp; {progressStepText[progressStep]}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Box
      sx={{
        display: 'flex',
        minHeight: '89vh',
        ml: 5,
        mt: 10,
      }}
    >
      <Stack spacing={50} sx={{ mb: 2 }}>
        <List>
          <Stack spacing={3}>
            {map(
              step => (
                <QuestionNavItem
                  active={progressStep === step}
                  key={step}
                  step={inc(step)}
                  text={progressStepText[step]}
                />
              ),
              range(0, totalSteps)
            )}
          </Stack>
        </List>
      </Stack>
      <Button
        href={
          'https://forms.monday.com/forms/4823e24a3640698828ff434d04338067?r=use1'
        }
        LinkComponent="a"
        sx={{
          backgroundColor: 'grey.4',
          bottom: 0,
          color: 'grey.2',
          position: 'absolute',
          textTransform: 'capitalize',
        }}
        variant="text"
      >
        <Typography
          sx={{
            alignItems: 'center',
            color: 'grey.1',
            display: 'flex',
            fontSize: 12,
          }}
          variant="caption"
        >
          <ChatIcon sx={{ mr: 1 }} /> Give Feedback
        </Typography>
      </Button>
    </Box>
  )
}

QuestionNavSidebar.propTypes = {
  handleBackButton: PropTypes.func,
  progressStep: PropTypes.number,
  progressStepText: PropTypes.array,
}

export default QuestionNavSidebar
