import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'

const ResourceFieldDisplay = ({ text, icon, isMultiline, text2 }) => (
  <Box sx={{ alignItems: 'center', display: 'flex' }}>
    <Box
      sx={{
        alignItems: 'center',
        background: theme => theme.palette.school.secondary,
        borderRadius: '100%',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        mr: 1,
      }}
    >
      {icon}
    </Box>
    <Stack spacing={0}>
      <Typography variant="body3">{text}</Typography>
      {isMultiline && <Typography variant="body3">{text2}</Typography>}
    </Stack>
  </Box>
)
ResourceFieldDisplay.propTypes = {
  icon: PropTypes.object,
  isMultiline: PropTypes.bool,
  text: PropTypes.string,
  text2: PropTypes.string,
}

export default ResourceFieldDisplay
