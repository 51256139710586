import { always, cond, equals, T } from 'ramda'
import { PrivacyTipRounded, Visibility, VisibilityOff, Lock } from "@mui/icons-material"

export const getPrivacyIcon = (privacyLabel) => {
    const privacyIcon = cond([
        [
          equals('confidential'),
          always(<Lock fontSize="small" sx={{ color: 'grey.2', mr: 1 }} />),
        ],
        [
          equals('private'),
          always(
            <VisibilityOff fontSize="small" sx={{ color: 'grey.2', mr: 1 }} />
          ),
        ],
        [
          equals('non-private'),
          always(
            <Visibility fontSize="small" sx={{ color: 'grey.2', mr: 1 }} />
          ),
        ],
        [
          equals('anonymous'),
          always(
            <PrivacyTipRounded fontSize="small" sx={{ color: 'grey.2', mr: 1 }} />
          ),
        ],
        [T, always(<Lock fontSize="small" sx={{ color: 'grey.2', mr: 1 }} />)],
      ])
    return privacyIcon(privacyLabel)
}