// import router from 'next/router'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import SchoolLogoImage from './SchoolLogoImage'
import { hexToRGB } from '../utils/hexToRGB'
import trackEvent from '../utils/trackEvent'
import { useNavigate } from 'react-router-dom'

const Home = ({ school }) => {
  const schoolPrimaryColorInRGB = hexToRGB(school.primary_color, 0.2)

  const navigate = useNavigate()

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box
        sx={{
          '& .MuiTypography-root': {
            textAlign: { xs: 'left', lg: 'center' },
          },
          'alignItems': 'center',
          'backgroundColor': school.primary_color,
          'backgroundImage': {
            xs:
              `linear-gradient(90deg, ${school.primary_color}, ${schoolPrimaryColorInRGB}),` +
              'url(' +
              `${
                school.triage_background
                  ? school.triage_background
                  : 'images/triage/overlay-doors-mobile.png'
              }` +
              ')',
            lg:
              `linear-gradient(90deg, ${schoolPrimaryColorInRGB}, ${school.primary_color}, ${schoolPrimaryColorInRGB}),` +
              'url(' +
              `${
                school.triage_background
                  ? school.triage_background
                  : '/images/triage/overlay-doors.png'
              }` +
              ')',
          },
          'backgroundPosition': 'center',
          'backgroundSize': 'cover',
          'display': 'flex',
          'height': '40vh',
          'justifyContent': { xs: 'left', lg: 'center' },
          'pl': 2,
        }}
      >
        <Typography
          component={'h1'}
          sx={{ color: '#fff', textAlign: 'center', width: '80%' }}
          variant="h1"
        >
          {school.triage_title
            ? school.triage_title
            : 'Life gets hard. Finding help is now easy.'}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'start',
          display: 'flex',
          flexDirection: 'column',
          mx: 'auto',
          pt: 4,
          width: { xs: '90%', lg: '50%' },
        }}
      >
        <Grid container>
          <Grid item sx={{ mr: 1.5 }} xs={1}>
            <SchoolLogoImage logo={school.flagImage} />
          </Grid>
          <Grid item sx={{ display: 'flex', flexDirection: 'column' }} xs={10}>
            <Stack spacing={1}>
              <Typography component={'h2'} sx={{ m: 0 }} variant="h4">
                Challenges come in many forms.
              </Typography>
              <Typography variant="body1">
                Answer a few questions to find the right resources, right now.
              </Typography>
            </Stack>
            <Stack
              sx={{
                bottom: { xs: '5%', lg: 'unset' },
                mt: { xs: 'unset', lg: 4 },
                position: { xs: 'absolute', lg: 'unset' },
                right: { xs: '10%', lg: 'unset' },
              }}
            >
              <Button
                onClick={() => {
                  navigate('/triage')
                  trackEvent('HC Home Screen', {
                    data: {},
                  })
                }}
                sx={{
                  gridGap: '8px',
                  ml: 'auto',
                  mt: 2,
                  p: 2,
                }}
                variant="single-select"
              >
                <ArrowForwardIcon fontSize="small" /> Get Started
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

Home.propTypes = {
  school: PropTypes.object.isRequired,
}

export default Home
