export const typography = {
  body1: {
    '@media (max-width:600px)': {
      fontSize: '18px',
      letterSpacing: 'unset',
      lineHeight: '27px',
    },
    fontFamily: 'mark-ot',
    fontSize: '20px',
    fontWeight: 400,
    letterSpacing: '0.1px',
    lineHeight: '28px',
  },
  body2: {
    '@media (max-width:600px)': {
      fontSize: '16px',
      letterSpacing: 'unset',
      lineHeight: '28px',
    },
    fontFamily: 'mark-ot',
    fontSize: '17px',
    fontWeight: 400,
    letterSpacing: '0.25px',
    lineHeight: '23px',
  },
  body3: {
    '@media (max-width:600px)': {
      fontSize: '16px',
      letterSpacing: 'unset',
      lineHeight: '28px',
    },
    fontFamily: 'mark-ot',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.25px',
    lineHeight: '23px',
  },
  buttonPrimary: {
    backgroundColor: 'school.primary',
    color: 'white',
    fontFamily: 'mark-ot-bold',
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },
  caption: {
    fontFamily: 'mark-ot',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: '19px',
  },
  h1: {
    '@media (max-width:600px)': {
      fontSize: '35px',
      lineHeight: '45px',
    },
    fontFamily: 'mark-ot',
    fontSize: '50px',
    fontWeight: 700,
    letterSpacing: '-1px',
    lineHeight: '63px',
  },
  h2: {
    '@media (max-width:600px)': {
      fontSize: '28px',
      lineHeight: '39px',
    },
    fontFamily: 'mark-ot',
    fontSize: '42px',
    fontWeight: 700,
    letterSpacing: '-1px',
    lineHeight: '53px',
  },
  h3: {
    '@media (max-width:600px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
    fontFamily: 'mark-ot',
    fontSize: '35px',
    fontWeight: 700,
    letterSpacing: '-1px',
    lineHeight: '42px',
  },
  h4: {
    '@media (max-width:600px)': {
      fontSize: '23px',
      lineHeight: '28px',
    },
    fontFamily: 'mark-ot-bold',
    fontSize: '29px',
    fontWeight: 700,
    letterSpacing: '-0.25px',
    lineHeight: '35px',
  },
  h5: {
    fontFamily: 'mark-ot-bold',
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  h6: {
    fontFamily: 'mark-ot',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  markOtBold: {
    fontFamily: 'mark-ot-bold',
    fontWeight: 500,
    letterSpacing: '1.5px',
    lineHeight: '16px',
  },
  needTag: {
    fontFamily: 'mark-ot',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '0.5px',
    lineHeight: '19px',
  },
  overline: {
    '@media (max-width:600px)': {
      fontSize: '14px',
      lineHeight: '15px',
    },
    fontFamily: 'mark-ot-bold',
    fontSize: '13px',
    fontWeight: 500,
    letterSpacing: '1.5px',
    lineHeight: '16px',
  },
}
