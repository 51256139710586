import HelpIcon from '@mui/icons-material/Help'
import PropTypes from 'prop-types'
import { Box, Typography, Tooltip } from '@mui/material'

const FHNToolTip = ({ message, tooltipLabel, color, icon, sx }) => (
  <Tooltip
    sx={{ lineHeight: 0.5 }}
    title={<Typography variant="caption">{message}</Typography>}
  >
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: 'fit-content',
        ...sx,
      }}
      variant="text"
    >
      {icon}
      <Typography
        sx={{
          color: color ? color : 'grey.2',
          fontWeight: '400',
          mr: 1,
          textTransform: 'capitalize',
        }}
        variant="caption"
      >
        {tooltipLabel}
      </Typography>
      <HelpIcon fontSize="small" sx={{ color: color ? color : 'grey.2' }} />
    </Box>
  </Tooltip>
)

FHNToolTip.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.string,
  sx: PropTypes.object,
  tooltipLabel: PropTypes.string,
}

export default FHNToolTip
