import React, { useEffect, useState } from 'react'

import sendAnalytics from '../../utils/sendAnalytics'
import ToolTip from '../FHNToolTip'
import ResourceField from '../Resource/ResourceField'
import { useInitDataContext } from '../../contexts/initDataContext'
import { getNeedsFromCategories } from '../../utils/getNeedsFromCategories'
import LocationBadge from '../LocationBadge'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import PropTypes from 'prop-types'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import {
  ListItem,
  Grid,
  Icon,
  CardContent,
  CardHeader,
  CardActionArea,
  List,
  Card,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import parse from 'html-react-parser'
import {
  equals,
  filter,
  includes,
  length,
  map,
  not,
  or,
  pluck,
  prop,
} from 'ramda'
import { getPrivacyIcon } from '../../utils/getPrivacyIcon'
import { useNavigate } from 'react-router-dom'

const StandardResourceCard = ({ resource, isEmergency, resourceAnalytics }) => {
  const [needIndex, setNeedIndex] = useState(6)
  const [resourceNeedsTitles, setResourceNeedsTitles] = useState([])
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { lookups, categories } = useInitDataContext()
  const navigate = useNavigate()
  const path = window.location.href
  useEffect(() => {
    if (categories) {
      const allNeeds = getNeedsFromCategories(categories)
      setResourceNeedsTitles(
        map(
          prop('name'),
          filter(
            need => includes(need.id, pluck('id', resource.needs)),
            allNeeds
          )
        )
      )
    }
  }, [categories])

  const handleClick = () => {
    sendAnalytics(path, 'resource-card', resourceAnalytics, 'resource')
    navigate('/resource/' + resource.id)
  }

  const handleExpandNeeds = () => {
    needIndex === 6 ? setNeedIndex(resourceNeedsTitles.length) : setNeedIndex(6)
  }

  return (
    <Card
      sx={{
        borderRadius: '8px',
        boxShadow: '0px 2px 4px 0px #5F5F5F26',
        shadow: '2dp',
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardHeader
          title={
            <Typography
              component="h2"
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'mark-ot-bold',
                justifyContent: 'space-between',
                mr: 1,
              }}
              variant="h5"
            >
              {resource.title}
              <ArrowCircleRightIcon sx={{ color: 'school.secondary' }} />
            </Typography>
          }
        />
      </CardActionArea>
      <CardContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            {!isEmergency && (
              <Typography variant="body2">
                {resource.resource_type_label}
              </Typography>
            )}
            <Grid container spacing={1}>
              {length(resource.location_text) > 0 &&
                resource.location_text?.map((location, ind) => (
                  <Grid item key={ind} sm={12} xs={6}>
                    <LocationBadge location={lookups.location_text[location]} />
                  </Grid>
                ))}
              {resource.privacy_label && (
                <Grid item xs={12}>
                  <ToolTip
                    icon={getPrivacyIcon(resource.privacy_label)}
                    message="Understanding how your information may be shared is important, this label indicates to what extent this service may share your information."
                    sx={{ pl: 0 }}
                    tooltipLabel={lookups.privacy_label[resource.privacy_label]}
                  />
                </Grid>
              )}
              {resource.hours_text &&
                equals('available 24/7', resource.hours_text) && (
                  <Grid item xs={12}>
                    <Typography
                      color="grey.2"
                      component="span"
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      textTransform="capitalize"
                      variant="body3"
                    >
                      <Brightness4Icon
                        sx={{
                          background: 'none',
                          color: 'grey.2',
                          p: 0.5,
                        }}
                      />
                      {resource.hours_text}
                    </Typography>
                  </Grid>
                )}
              {resource.location_access_criterion?.name && (
                <Grid item xs={12}>
                  <Typography
                    color="grey.2"
                    component="span"
                    sx={{ alignItems: 'center', display: 'flex' }}
                    variant="body3"
                  >
                    <LocationOnIcon
                      sx={{
                        background: 'none',
                        color: 'grey.2',
                        p: 0.5,
                      }}
                    />
                    {resource.location_access_criterion.name}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Stack>

          {isEmergency ? (
            <Stack spacing={1}>
              {resource.phone_number && (
                <ResourceField
                  icon={<PhoneIcon sx={{ p: 0.5 }} />}
                  isPhoneNumber={true}
                  link={'tel:' + resource.phone_number}
                  resourceAnalytics={resourceAnalytics}
                  text={resource.phone_number}
                />
              )}

              {resource.location_line_1 && resource.location_map_url && (
                <ResourceField
                  icon={<LocationOnIcon sx={{ p: 0.5 }} />}
                  link={resource.location_map_url}
                  resourceAnalytics={resourceAnalytics}
                  text={resource.location_line_1}
                />
              )}
            </Stack>
          ) : (
            <Typography variant="caption">
              {parse(resource.summary ?? '')}
            </Typography>
          )}

          {resourceNeedsTitles.length > 0 && or(not(isMobile), isEmergency) && (
            <>
              <List
                dense
                disablePadding
                subheader={
                  <Typography
                    color="grey.2"
                    sx={{ fontSize: '12px', letterSpacing: '1.5px' }}
                    variant="body3"
                  >
                    HELPS WITH
                  </Typography>
                }
              >
                {resourceNeedsTitles.slice(0, needIndex).map((title, i) => (
                  <ListItem key={i} sx={{ m: 1, p: 0 }}>
                    <Typography
                      sx={{
                        background: theme => theme.palette.grey[4],
                        borderRadius: '8px',
                        px: 1,
                        py: 0.5,
                      }}
                      variant="needTag"
                    >
                      {title}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {resourceNeedsTitles.length > 6 && or(not(isMobile), isEmergency) && (
            <Button
              aria-label={
                needIndex > 6 ? 'See Less Content' : 'See More Content'
              }
              onClick={handleExpandNeeds}
              variant="text"
            >
              <Icon>
                {needIndex > 6 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </Icon>
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

StandardResourceCard.propTypes = {
  isEmergency: PropTypes.bool,
  resource: PropTypes.object,
  resourceAnalytics: PropTypes.object,
}

export default StandardResourceCard
