import PropTypes from 'prop-types'

const FHNLogo = ({ color }) => (
  <svg
    fill="none"
    height="36"
    viewBox="0 0 189 36"
    width="189"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M17.9999 12.51C17.5673 12.51 17.1524 12.6819 16.8464 12.9878C16.5405 13.2937 16.3687 13.7086 16.3687 14.1413C16.5561 16.4991 17.1063 18.8138 17.9999 21.0038C18.8975 18.8151 19.4479 16.4997 19.6312 14.1413C19.6312 13.7086 19.4593 13.2937 19.1534 12.9878C18.8475 12.6819 18.4325 12.51 17.9999 12.51Z"
        fill={color}
      />
      <path
        d="M18.0002 25.155C15.7502 25.155 14.2314 26.0775 14.2314 26.8988C14.2314 27.72 15.8402 28.6538 18.0002 28.6538C20.1602 28.6538 21.7689 27.7313 21.7689 26.8988C21.7689 26.0663 20.2502 25.155 18.0002 25.155ZM18.0002 27.5063C17.0777 27.5063 16.3239 27.2363 16.3239 26.8988C16.3239 26.5613 17.0777 26.2913 18.0002 26.2913C18.9227 26.2913 19.6764 26.5613 19.6764 26.8988C19.6764 27.2363 18.9227 27.5063 18.0002 27.5063Z"
        fill={color}
      />
      <path
        d="M15.221 14.1412C15.1869 13.7305 14.9997 13.3476 14.6965 13.0685C14.3933 12.7894 13.9962 12.6345 13.5841 12.6345C13.172 12.6345 12.775 12.7894 12.4718 13.0685C12.1686 13.3476 11.9813 13.7305 11.9473 14.1412C11.9473 15.6375 15.4685 19.1925 16.5598 20.3512C15.7961 18.3626 15.3445 16.2678 15.221 14.1412Z"
        fill={color}
      />
      <path
        d="M18 0C14.4399 0 10.9598 1.05568 7.99974 3.03355C5.03966 5.01141 2.73255 7.82263 1.37018 11.1117C0.00779906 14.4008 -0.348661 18.02 0.345873 21.5116C1.04041 25.0033 2.75474 28.2106 5.27209 30.7279C7.78943 33.2453 10.9967 34.9596 14.4884 35.6541C17.98 36.3487 21.5992 35.9922 24.8883 34.6298C28.1774 33.2675 30.9886 30.9604 32.9665 28.0003C34.9443 25.0402 36 21.5601 36 18C36 13.2261 34.1036 8.64773 30.7279 5.27208C27.3523 1.89642 22.7739 0 18 0V0ZM13.0388 26.8988C13.0388 23.31 22.9613 23.2987 22.9613 26.8988C22.9613 30.4988 13.0388 30.4988 13.0388 26.8988ZM19.3163 22.1962C19.1516 22.383 18.949 22.5326 18.7221 22.635C18.4951 22.7374 18.249 22.7904 18 22.7904C17.751 22.7904 17.5049 22.7374 17.2779 22.635C17.051 22.5326 16.8485 22.383 16.6838 22.1962C14.7038 19.9462 10.8 16.38 10.8 14.1412C10.8 10.125 14.0738 6.40125 18 6.40125C21.9263 6.40125 25.2 10.1137 25.2 14.1412C25.2 16.3687 21.375 19.89 19.3163 22.1962Z"
        fill={color}
      />
      <path
        d="M20.7787 14.1187C20.6552 16.2454 20.2036 18.3401 19.4399 20.3287C20.5649 19.08 24.0524 15.6375 24.0524 14.1187C24.0184 13.708 23.8311 13.3252 23.5279 13.0461C23.2247 12.767 22.8277 12.6121 22.4156 12.6121C22.0035 12.6121 21.6064 12.767 21.3032 13.0461C21 13.3252 20.8128 13.708 20.7787 14.1187Z"
        fill={color}
      />
    </g>
    <path
      d="M56.4803 17.0151H48.5807V12.9118H56.9802V10.5639H46.125V25.9239H48.5807V19.363H56.4803V17.0151Z"
      fill={color}
    />
    <path
      d="M62.0677 15.4132H59.7858V25.9239H62.0677V15.4132ZM60.9159 10.1579C60.014 10.1579 59.3294 10.8711 59.3294 11.7817C59.3294 12.6923 60.014 13.3835 60.9159 13.3835C61.8177 13.3835 62.524 12.6923 62.524 11.7817C62.524 10.8711 61.8177 10.1579 60.9159 10.1579Z"
      fill={color}
    />
    <path
      d="M71.0768 15.139C70.0119 15.139 68.7189 15.4571 67.8822 16.6969V15.4132H65.622V25.9239H67.9039V20.0981C67.9039 18.2987 69.1318 17.3223 70.5987 17.3223C72.1199 17.3223 73.0218 18.3097 73.0218 20.0761V25.9239H75.3037V19.3191C75.3037 16.8615 73.5542 15.139 71.0768 15.139Z"
      fill={color}
    />
    <path
      d="M83.214 15.139C80.3019 15.139 78.0091 17.4539 78.0091 20.6686C78.0091 23.8832 80.3019 26.1982 83.214 26.1982C84.6917 26.1982 85.7675 25.5948 86.4846 24.6622V25.9239H88.7556V10.125H86.4846V16.6859C85.7675 15.7534 84.6917 15.139 83.214 15.139ZM83.4856 17.3003C85.3654 17.3003 86.6259 18.7486 86.6259 20.6686C86.6259 22.5886 85.3654 24.0368 83.4856 24.0368C81.5297 24.0368 80.3453 22.5118 80.3453 20.6686C80.3453 18.8254 81.5297 17.3003 83.4856 17.3003Z"
      fill={color}
    />
    <path
      d="M96.634 10.5639H93.0048V25.9239H96.634V19.8677H103.034V25.9239H106.663V10.5639H103.034V16.4775H96.634V10.5639Z"
      fill={color}
    />
    <path
      d="M114.147 14.8647C110.909 14.8647 108.681 17.2126 108.681 20.6028C108.681 23.96 110.952 26.2092 114.299 26.2092C116.266 26.2092 117.885 25.5619 118.993 24.4428L116.798 22.3801C116.125 22.9945 115.266 23.3237 114.473 23.3237C113.299 23.3237 112.474 22.7751 112.191 21.6012H119.417C119.46 21.3708 119.482 20.888 119.482 20.5369C119.471 17.1687 117.309 14.8647 114.147 14.8647ZM114.158 17.7831C115.147 17.7831 115.81 18.3536 116.081 19.4288H112.202C112.463 18.3646 113.104 17.7831 114.158 17.7831Z"
      fill={color}
    />
    <path d="M124.702 10.125H121.301V25.9239H124.702V10.125Z" fill={color} />
    <path
      d="M133.408 14.8647C131.963 14.8647 130.996 15.4791 130.355 16.3239V15.1609H127.073V30.3125H130.464V24.9036C131.105 25.6716 132.039 26.2092 133.408 26.2092C136.255 26.2092 138.374 23.7077 138.374 20.526C138.374 17.3662 136.255 14.8647 133.408 14.8647ZM132.593 18.0683C133.963 18.0683 134.875 19.1326 134.875 20.5369C134.875 21.9413 133.963 23.0055 132.593 23.0055C131.257 23.0055 130.322 21.9632 130.322 20.5369C130.322 19.1106 131.257 18.0683 132.593 18.0683Z"
      fill={color}
    />
    <path
      d="M155.315 10.5639H152.859V21.678L144.362 10.5639H142.145V25.9239H144.601L144.612 14.8098L153.098 25.9239H155.315V10.5639Z"
      fill={color}
    />
    <path
      d="M164.005 15.139C160.789 15.139 158.409 17.5198 158.409 20.6686C158.409 23.8174 160.789 26.1982 164.005 26.1982C167.222 26.1982 169.612 23.8174 169.612 20.6686C169.612 17.5198 167.222 15.139 164.005 15.139ZM164.005 17.3003C165.863 17.3003 167.276 18.6827 167.276 20.6686C167.276 22.6544 165.863 24.0368 164.005 24.0368C162.147 24.0368 160.745 22.6544 160.745 20.6686C160.745 18.6827 162.147 17.3003 164.005 17.3003Z"
      fill={color}
    />
    <path
      d="M181.029 15.4132H178.454L175.889 23.4005L173.445 15.4132H171.021L174.466 25.9239H177.139L179.714 18.0025L182.29 25.9239H185.006L188.451 15.4132H186.082L183.626 23.4444L181.029 15.4132Z"
      fill={color}
    />
    <defs>
      <clipPath id="clip0">
        <rect fill={color} height="36" width="36" />
      </clipPath>
    </defs>
  </svg>
)

FHNLogo.propTypes = {
  color: PropTypes.string.isRequired,
}

export default FHNLogo
