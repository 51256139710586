import { useState, useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import sendAnalytics from '../../utils/sendAnalytics'
import { VelocityTransitionGroup } from 'velocity-react'
import SortMenu, { SORT_OPTION_MOST_RELEVANT } from './SortMenu'
import YouResourceCard from '../ResourceCards/YouResourceCard'
import { useInitDataContext } from '../../contexts/initDataContext'
import SortedStandardResources from './SortedStandardResources'
import Box from '@mui/material/Box'
import { Button, Container, Stack } from '@mui/material'
import { Masonry } from '@mui/lab'
import { ResourcesContextProvider } from '../../contexts/resourcesContext'
import ResourceHeader from './ResourceHeader'
import useMediaQuery from '@mui/material/useMediaQuery'
import ResourcesSkeletonLoader from './ResourcesSkeletonLoader'
import { useGetResourcesQuery } from '../../api/triageApi'
import { ArrowBackIos } from '@mui/icons-material'
import { getOrgDomain } from '../../utils/sessionStorage/orgDomain'
import {
  concat,
  equals,
  gt,
  isEmpty,
  isNil,
  length,
  map,
  or,
  prop,
  propOr,
  reduce,
  sortBy,
} from 'ramda'
import { QuestionContext } from '../../contexts/questionContext'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const enterAnimation = {
  animation: { opacity: 1 },
  duration: 500,
  easing: 'easeOutCubic',
  style: { opacity: 0 },
}

function Resources({ school }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { categories, loading, questions } = useInitDataContext()
  const { previousNeeds, resetContext, seeAllTopics } =
    useContext(QuestionContext)
  const [selectedSortOption, setSelectedSortOption] = useState(
    SORT_OPTION_MOST_RELEVANT
  )
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  // info gathered from router
  const criteria = searchParams.get('criteria') || ''
  const criteriaArray = searchParams.get('criteria') ? criteria.split(',') : []
  const roleQuestionParam = criteriaArray.length
    ? parseInt(criteriaArray[0])
    : null
  const needs = searchParams.get('needs') || ''
  const multiselect = searchParams.get('multiselect') || ''
  const isEmergency = location.pathname.includes('/emergency/')
  const isHelper = location.pathname.includes('them')
  const resourcesAnalytics = {
    allTopics: seeAllTopics,
    criteria,
    isEmergency,
    isHelper,
    needs: previousNeeds,
    path: location.pathname,
    role_answer: roleQuestionParam == 1 ? 'Student' : 'Faculty',
  }

  useEffect(() => {
    localStorage.setItem('previousPath', location.pathname)
  }, [])

  const allNeeds = useMemo(() => {
    if (isNil(categories)) return []
    return reduce(
      (needs, category) =>
        concat(needs, map(prop(['id']), prop('needs', category))),
      [],
      categories
    )
  }, [categories])

  const { data: response, isFetching } = useGetResourcesQuery(
    new URLSearchParams({
      criteria: gt(length(criteria), 0) ? criteria : '',
      emergency: isEmergency ? 1 : 0,
      multiselect: gt(length(multiselect), 0) ? multiselect : '',
      // we hit a validation error if needs are empty. so if needs.length is 0 or needs are "none", we need to get all needs
      needs: or(equals('none', needs), equals('', needs)) ? allNeeds : needs,
      org_domain: getOrgDomain(),
    }),
    {
      // rtk query does not refresh with the emergency parameter change, so we have to force refetch
      refetchOnMountOrArgChange: true,
      skip: isEmpty(allNeeds),
    }
  )

  return (
    <VelocityTransitionGroup enter={enterAnimation} runOnMount={true}>
      <ResourcesContextProvider
        categories={categories}
        criteria={criteria}
        isEmergency={isEmergency}
        isHelper={isHelper}
        needs={needs}
        questions={questions}
      >
        <Container sx={{ minHeight: '100vh' }}>
          <Stack>
            {school.link_to_you ? (
              <Button
                href={'https://' + school.domain}
                LinkComponent="a"
                onClick={() =>
                  sendAnalytics(
                    location.pathname,
                    'you-link',
                    resourcesAnalytics,
                    'resources-page'
                  )
                }
                rel="noreferrer"
                startIcon={
                  <Box
                    alt="You Icon"
                    component="img"
                    display="inline flex"
                    height="25px"
                    src="/images/triage/you-icon.png"
                    sx={{ filter: 'invert(1)' }}
                  />
                }
                sx={{
                  backgroundColor: 'school.primary',
                  borderRadius: '20px',
                  color: 'white',
                  '&:hover': {
                    color: 'school.primary',
                  },
                  fontFamily: 'mark-ot-bold',
                  mb: -3,
                  mt: 3,
                  textDecoration: 'none',
                  textTransform: 'none',
                  width: 'fit-content',
                }}
                target="_blank"
                variant="buttonPrimary"
              >
                Visit YOU at {school.letters}
              </Button>
            ) : (
              <></>
            )}
            <ResourceHeader
              pageType={'resources-page'}
              school={school}
              sx={{ mb: 2 }}
            />

            <Box
              mt={{ xs: 3 }}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button
                onClick={() => {
                  sendAnalytics(
                    location.pathname,
                    'start-new-search',
                    resourcesAnalytics,
                    'resources-page'
                  )
                  resetContext()
                  navigate('/triage')
                }}
                sx={{
                  color: 'grey.2',
                }}
                variant="back-button"
              >
                <ArrowBackIos
                  sx={{
                    fontSize: 'small',
                    mr: 1,
                  }}
                />{' '}
                Start a new search
              </Button>
              <SortMenu
                hasOnCampus={response?.has_on_campus}
                onChange={setSelectedSortOption}
              />
            </Box>

            {isFetching || loading ? (
              <ResourcesSkeletonLoader />
            ) : (
              <Stack mt={2} spacing={2}>
                <SortedStandardResources
                  selectedSortOption={selectedSortOption}
                  standard_resources={propOr(
                    [],
                    'standard',
                    response?.resources
                  )}
                />

                <Masonry columns={isMobile ? 1 : 3} spacing={2}>
                  {sortBy(
                    prop('title'),
                    propOr([], 'you', response?.resources)
                  ).map((resource, i) => (
                    <YouResourceCard
                      key={i}
                      resource={resource}
                      school={school}
                    />
                  ))}
                </Masonry>
              </Stack>
            )}
          </Stack>
        </Container>
      </ResourcesContextProvider>
    </VelocityTransitionGroup>
  )
}

Resources.propTypes = {
  school: PropTypes.object,
}

export default Resources
